<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import ReloadingComponent from "../../components/reloading-component.svelte";
  import { getRequestById } from "../service";
  import { headerInformation, goBack, showMenu } from "../../header/service";
  import DeclinedComponent from "./declined.svelte";
  import { environments } from "../../assets/environments";
  import { queryParams } from "../../Router";

  export let propertyInformation;
  export let guestAppSettings;
  export let params;
  let loading = true;
  let request;

  onMount(() => {
    initComponent();
  });

  const initComponent = () => {
    loading = true;
    goBack.set(`/${propertyInformation.id}/my-requests`);
    showMenu.set(false);
    headerInformation.set(null);
    getRequestById({
      propertyId: propertyInformation.id,
      requestId: $params.requestId,
    })
      .then((response) => response.json())
      .then((requestData) => {
        request = requestData;
        if (request.type == "check-in") {
          headerInformation.set({
            title: "Check in remote",
            logo: "/images/reservation.svg",
          });
          goBack.set(null);
        } else {
          headerInformation.set({
            title: propertyInformation.name,
            logo: propertyInformation.logo,
          });
        }

        if (!window.location.host.includes("localhost")) {
          if (
            request.type == "check-in" &&
            request.statusCodename == "declined"
          ) {
          } else {
            window.location.href = environments.reservationsWeb
              .concat("/#/properties/")
              .concat(propertyInformation.id)
              .concat("/")
              .concat(request.reservationId)
              .concat("/completed-request/")
              .concat(request.id)
              .concat($queryParams.code ? "?code=" + $queryParams.code : "");
            return;
          }
        }

        loading = false;
      });
  };
</script>

{#if loading}
  <ReloadingComponent />
{:else if request.type == "check-in" && request.statusCodename == "declined"}
  <DeclinedComponent
    bind:propertyInformation
    bind:guestAppSettings
    bind:request
  />
{:else}
  <section class="container-fluid">
    <div class="card mb-3">
      <div class="container">
        <div
          class="col s12 status__request svelte-lxcbow"
          style="background: rgb(54, 54, 54);"
        >
          <div class="col s12 status__request__title svelte-lxcbow">
            <span
              class="status__request__title__text en svelte-lxcbow"
              style="color: rgb(244, 244, 244);"
              >We are validating your data</span
            >
          </div>
          <div class="col s12 status__request__title--desktop svelte-lxcbow">
            <span
              class="status__request__title__text en svelte-lxcbow"
              style="color: rgb(244, 244, 244);"
              >We are validating your data</span
            >
          </div>
          <div
            class="col xl12 l12 m12 s12 status__request__progress__bar svelte-lxcbow"
          >
            <div class="status__bar svelte-1ox136u">
              <div class="status__bar__section__left svelte-1ox136u">
                <div class="preloader-wrapper big active svelte-1ox136u">
                  <div class="spinner-layer spinner-layer--dark svelte-1ox136u">
                    <div class="circle-clipper left">
                      <div class="circle svelte-1ox136u" />
                    </div>
                    <div class="gap-patch">
                      <div class="circle svelte-1ox136u" />
                    </div>
                    <div class="circle-clipper right">
                      <div class="circle svelte-1ox136u" />
                    </div>
                  </div>
                </div>
                <div
                  class="circle__icon svelte-1ox136u"
                  style="border: 8px solid rgb(104, 108, 109);"
                >
                  <i
                    class="material-icons icon--validate svelte-1ox136u"
                    style="color: rgb(244, 244, 244);">error</i
                  >
                </div>
                <span
                  class="validate__status__text en svelte-1ox136u"
                  style="color: rgb(244, 244, 244);">Validate</span
                >
              </div>
              <div class="status__bar__section__half svelte-1ox136u">
                <div
                  class="line__connect__left svelte-1ox136u"
                  style="background: rgb(104, 108, 109);"
                />
                <div
                  class="circle__icon svelte-1ox136u"
                  style="border: 8px solid rgb(104, 108, 109);"
                >
                  <i
                    class="material-icons icon--accept svelte-1ox136u"
                    style="color: rgb(104, 108, 109);">watch_later</i
                  >
                </div>
                <div
                  class="line__connect__right svelte-1ox136u"
                  style="background: rgb(104, 108, 109);"
                />
                <span
                  class="accept__status__text en svelte-1ox136u"
                  style="color: rgb(104, 108, 109);">Accepted</span
                >
              </div>
              <div class="status__bar__section__right svelte-1ox136u">
                <div
                  class="circle__icon svelte-1ox136u"
                  style="border: 8px solid rgb(104, 108, 109);"
                >
                  <i
                    class="material-icons icon--finish svelte-1ox136u"
                    style="color: rgb(104, 108, 109);">check_circle</i
                  >
                </div>
                <span
                  class="finish__status__text en svelte-1ox136u"
                  style="color: rgb(104, 108, 109);">Confirmed</span
                >
              </div>
            </div>
          </div>
          <div class="col s12 status__request__link svelte-lxcbow">
            <span
              class="status__request__link__text en svelte-lxcbow"
              style="text-decoration: none; cursor: default; color: rgb(195, 195, 195);"
              >We will send you an email when the validation of your data is
              completed.</span
            >
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container">
    <div class="card mb-3">hola</div>
  </section>
{/if}
